import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { ISuggest } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'

interface EventAutosuggestItemProps {
  data: ISuggest
  search: string
}

export const EventAutosuggestItem: React.FC<EventAutosuggestItemProps> = ({ data, search }) => {
  const logo = useDefaultLogo(data.logo)

  return (
    <Link
      prefetch={false}
      href={data.slug}
      className="flex w-full relative bg-white hover:bg-gray-400 cursor-pointer items-center px-8"
    >
      <Image
        className="border border-solid border-mist rounded-[.1875rem]"
        src={logo}
        alt="logo"
        width={20}
        height={20}
      />
      <Highlighter
        className="text-textdark font-primary font-normal text-[.875rem] leading-7 ml-3"
        highlightClassName="font-bold"
        searchWords={[search]}
        textToHighlight={data.keyword}
      />
    </Link>
  )
}
