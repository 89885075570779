import Image from 'next/image'
import Link from 'next/link'
import { useEffect } from 'react'
import { SITE_URL } from 'src/config/config'
import { IContentItem } from 'src/graphql/generated/hooks'
import { isContentItem, isContentListItemFragment } from 'src/utils/ContentDataFragment'
import { getContentDefaultImageUrl } from 'src/utils/getContentDefaultImageUrl'

import { HtmlContentViewer } from '../global/HtmlContentViewer/HtmlContentViewer'
import { InsightCardTags } from '../Insights/InsightCardTags'

export interface ArticleCardProps {
  item: IContentItem
  key?: number
  openTooltipCardId?: string | null
  setOpenTooltipCardId: (id: string | null) => void
  subjectMatterPopup?: number
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
  item,
  openTooltipCardId,
  setOpenTooltipCardId,
  subjectMatterPopup,
}) => {
  const defaultImageUrl = getContentDefaultImageUrl(item)
  //const logo = useDefaultLogo(defaultImageUrl)
  const contentType = item.contentType?.toLowerCase()
  const logo = `${SITE_URL}/${contentType}_logo.svg`
  const snippet =
    (isContentListItemFragment(item) ? item.attributes?.snippet : isContentItem(item) ? item.snippet : '') || ''

  useEffect(() => {
    setOpenTooltipCardId(null)
  }, [subjectMatterPopup])

  return (
    <div className="carousel-card w-[1007px] h-[232px] bg-white flex items-center p-[24px] gap-[56px]">
      <Link prefetch={false} href={`/contents/${item?.slug}`} passHref>
        <Image
          className="w-[258px] h-[172px] rounded-lg object-fill bg-clouds carousel-images"
          src={defaultImageUrl || logo || ''}
          alt="Article"
          width={258}
          height={172}
        />
      </Link>

      <div className="flex flex-col justify-center w-[645px]">
        <div className=" self-start h-[90px]">
          <Link prefetch={false} href={`/contents/${item?.slug}`} passHref>
            <h2 className="card-heading font-secondary text-[30px] font-bold text-left text-[#011D58] line-clamp-2">
              {item?.title?.trim()}
            </h2>
          </Link>
        </div>

        <div className="gap-[8px] mt-[12px] mb-[16px] h-[22px] relative">
          {item?.subjectMatters && (
            <InsightCardTags
              cardId={(item?.id || '') as string}
              tagItems={item?.subjectMatters}
              openTooltipCardId={openTooltipCardId}
              setOpenTooltipCardId={setOpenTooltipCardId}
              containerSize={577}
              subjectMatterPopup={subjectMatterPopup}
            />
          )}
        </div>

        <Link prefetch={false} href={`/contents/${item?.slug}`} passHref>
          <HtmlContentViewer
            className="self-end h-[46px] font-primary text-[16px] text-[#333333] font-normal   tracking-0 line-clamp-2  html-show w-full"
            html={snippet}
          />
        </Link>
      </div>
    </div>
  )
}
