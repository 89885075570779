import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { Dispatch } from 'react'
import { useDispatch } from 'react-redux'
import { THEME } from 'src/config/config'
import { push } from 'src/store/actions/origin'

import { OriginItem } from '../../store/types/origin'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SolutionOriginRecordProps extends React.ComponentProps<any> {
  toUrl: string
  className?: string
  children: React.ReactNode
  topicName?: string
  subTopicName?: string
  solutionName?: string
  companyName?: string
  increaseSolutionCountAsync?: () => void
}

export const LinkWithOrigin: React.FC<SolutionOriginRecordProps> = ({
  toUrl,
  className,
  children,
  topicName,
  subTopicName,
  solutionName,
  companyName,
  increaseSolutionCountAsync,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: Dispatch<any> = useDispatch()
  const router = useRouter()
  function handleClick() {
    const originUrl = router.asPath
    const originItem: OriginItem = {
      originPath: originUrl,
      topicName: topicName,
      subTopicName: subTopicName,
      solutionName: solutionName,
      companyName: companyName,
    }
    // Check if increaseSolutionCountAsync is a function and call it
    if (typeof increaseSolutionCountAsync === 'function') {
      increaseSolutionCountAsync()
    }
    const action = push(originItem)
    dispatch(action)
    router.push(toUrl, toUrl, { shallow: false })
  }

  return (
    <Link
      prefetch={false}
      href={toUrl}
      onClick={(e) => {
        if (THEME === 'ilta') {
          handleClick()
          e.preventDefault()
        }
        if (THEME === 'legaltech') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const evt: any = window.event
          if (!evt.ctrlKey && !evt.metaKey) {
            handleClick()
            e.preventDefault()
          }
        }
      }}
      className={className}
    >
      {children}
    </Link>
  )
}
