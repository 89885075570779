import Link from 'next/link'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { ISuggest } from 'src/graphql/generated/hooks'

interface CategoryAutosuggestItemProps {
  data: ISuggest
  search: string
}

export const CategoryAutosuggestItem: React.FC<CategoryAutosuggestItemProps> = ({ data, search }) => {
  return (
    <Link
      prefetch={false}
      href={data.slug}
      className="flex w-full relative bg-white hover:bg-gray-400 cursor-pointer px-8"
    >
      <Highlighter
        className="text-textdark font-primary font-normal text-[.875rem] leading-7"
        highlightClassName="font-bold"
        searchWords={[search]}
        textToHighlight={data.keyword}
      />
    </Link>
  )
}
